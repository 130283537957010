import React from 'react';
import "./about.css"
import { RiCodeBoxFill } from "react-icons/ri";
import { FaGraduationCap } from "react-icons/fa6";
import { FaGithubAlt } from "react-icons/fa";
import { VscGithubProject } from 'react-icons/vsc';

const info = () => {
  return (
    <div className="about__info grid">
        <div className="about__box">
          <i className='about__icon'>
            <RiCodeBoxFill />
          </i>
          <h3 className="about__title">Programming Experience</h3>
          <span className="about__subtitle">4 Years</span>
        </div>

        <div className="about__box">
          <i className='about__icon'>
            <VscGithubProject />
          </i>
          <h3 className="about__title">Completed</h3>
          <span className="about__subtitle">10+ Projects</span>
        </div>

        <div className="about__box">
          <i className='about__icon'>
            <FaGraduationCap />
          </i>
          <h3 className="about__title">Education</h3>
          <span className="about__subtitle">Final year <br/> Bachelor's Degree <br/> in <br/> Computer Science</span>
        </div>
    </div>
  )
}

export default info