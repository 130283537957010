import React, { useState } from 'react';
import "./header.css";
import logo from "../../assets/logo.png";

const Header = () => {
    /* Change background header */
    window.addEventListener("scroll", 
        function () {
            // document.querySelector is a method in JavaScript that 
            // allows you to select an element from the DOM (Document Object Model) using a CSS selector
            const header = document.querySelector(".header");
            // when the scroll is higher than 200 viewport height, 
            // add the scroll-header class to a tag with the header tag
            // `this` refers to the object that is currently executing the code
            if (this.scrollY >= 80) header.classList.add("scroll-header");
            else header.classList.remove("scroll-header");
    });

    /* Toggle Menu*/
    /* Below is a 'Hook', Hook is a special function that lets you “hook into” React features. */
    /* 'Toggle', the first value, is our current state */
    /* 'showMenu', the second value, is used to update our state */
    const[Toggle, showMenu] = useState(false); /* 'useState()' add React state to function components */
    const [activeNav, setActiveNav] = useState("#welcome");

    return (
        <header className="header">
            <nav className="nav container">
                <a href="" className="nav__logo">
                    <img src={logo} alt="" className="nav__logo-img"/>
                </a>
                
                <div className={Toggle ? "nav__menu show-menu" : "nav__menu"}>
                    <ul className="nav__list grid">
                        <li className="nav__item">
                            <a 
                            href="#welcome" 
                            onClick={() => setActiveNav('#welcome')} 
                            className={activeNav === "#welcome" ? "nav__link active-link" : "nav__link"}
                            >
                                <i className="uil uil-estate nav__icon"></i> Welcome
                            </a> 
                        </li>

                        <li className="nav__item">
                            <a href="#about" onClick={() => setActiveNav('#about')} 
                            className={activeNav === "#about" ? 
                            "nav__link active-link" : 
                            "nav__link"}>
                                <i className="uil uil-user nav__icon"></i> About
                            </a>
                        </li>

                        <li className="nav__item">
                            <a href="#skills" onClick={() => setActiveNav('#Skills')} 
                            className={activeNav === "#skills" ? 
                            "nav__link active-link" : 
                            "nav__link"}>
                                <i className="uil uil-file-alt nav__icon"></i> Skills
                            </a>
                        </li>

                        <li className="nav__item">
                            <a href="#achievements" onClick={() => setActiveNav('#achievements')} 
                            className={activeNav === "#achievements" ? 
                            "nav__link active-link" : 
                            "nav__link"}>
                                <i className="uil uil-briefcase-alt nav__icon"></i> Achievement
                            </a>
                        </li>

                        <li className="nav__item">
                            <a href="#projects" onClick={() => setActiveNav('#projects')} 
                            className={activeNav === "#projects" ? 
                            "nav__link active-link" : 
                            "nav__link"}>
                                <i className="uil uil-scenery nav__icon"></i> Projects
                            </a>
                        </li>

                        <li className="nav__item">
                            <a href="#contact" onClick={() => setActiveNav('#contact')} 
                            className={activeNav === "#contact" ? 
                            "nav__link active-link" : 
                            "nav__link"}>
                                <i className="uil uil-message nav__icon"></i> Contact
                            </a>
                        </li>
                    </ul>
                    <i className="uil uil-times nav__close" onClick={() => showMenu(!Toggle)}></i>
                </div>
                <div className="nav__toggle" onClick={() => showMenu(!Toggle)}>
                    <i className="uil uil-apps"></i>
                </div>
            </nav>
        </header>

    )
}

export default Header