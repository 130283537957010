import Image1 from "../../assets/testimonial1.png";
import Image2 from "../../assets/testimonial2.png";
import Image3 from "../../assets/testimonial3.png";
import Image4 from "../../assets/testimonial4.png";
import Image5 from "../../assets/testimonial5.png";
import Image6 from "../../assets/testimonial6.png";
import Image7 from "../../assets/testimonial7.png";
import Image8 from "../../assets/testimonial8.png";

export const Data = [
    {
        id: 1,
        image: Image3,
        title: "Wataru Nakagawa",
        description:
            "I have genuine respect for Brandon's rich technical and interpersonal skills; being friends and working with him is a highly motivational experience.",
    },
    {
        id: 2,
        image: Image4,
        title: "Seow Kit Chong",
        description:
            "Brandon has a high level of rationality, creativity, and thoughtfulness. He excels in tasks requiring a high level of concentration and logical thinking.",
    },
    {
        id: 3,
        image: Image1,
        title: "Lo Sau Kang",
        description:
            "Strong fundamental by doing lots of coding questions. Always willing to help others in homework.",
    },
    {
        id: 4,
        image: Image2,
        title: "Ang Xu Tian",
        description:
            "Strong aptitude for problem-solving, critical thinking skills. Brandon has potential to excel in tech industry.",
    },
    {
        id: 5,
        image: Image5,
        title: "Yong Jun Fai",
        description:
            "Brandon is a genuine and supportive friend who possesses rich knowledge in technical and soft skills, yet he remains remarkably humble and willing to help his peers whenever they are in need.",
    },
    {
        id: 6,
        image: Image6,
        title: "They Kai Zhe",
        description:
            "Brandon is like time. He makes progress so fast that I always never notice how far he's gotten within such a short time.",
    },
    {
        id: 7,
        image: Image7,
        title: "Benjamin Han Jia Shen",
        description:
            "After working with Brandon, I can confidently say that he's a very reliable person and a team-player. He leads well in a team and has proved that by achieving numerous great results.",
    },
    {
        id: 8,
        image: Image8,
        title: "Kho Kai Hong",
        description:
            "Brandon is a quick learner and is enthusiastic about developing his programming skills. He is also a team player who helps his teammates when they face any problems.",
    },

];
