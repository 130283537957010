import React, { useState, useEffect } from 'react';
import "./services.css";
import pcms from "../../assets/pcms.png";
import deepracer from "../../assets/deepracer.png";
import microsoft from "../../assets/microsoft.png";
import helsinki from "../../assets/helsinki.jpg";
import cgpa from "../../assets/apuy2.png"
import vclist2022 from "../../assets/vclist2022.png"
import vclist2023 from "../../assets/vclist2023.png"
import ieee from "../../assets/ieee.png"
import branlitwin from "../../assets/branlit_winner.png"
import safetytech from "../../assets/safetytech.jpg"
import { TbCloudComputing } from "react-icons/tb";
import { FaGoogle, FaAws,  } from "react-icons/fa";
import { SiMicrosoftazure } from "react-icons/si";


const Services = () => {
    const [toggleState, setToggleState] = useState(0);

    const toggleTab = (index) => {
        setToggleState(index);
        if (index === 1 || index === 2  || index === 3) {
            // Disable scrolling when the modal opens
            document.body.classList.add('no-scroll');
        } else {
            // Enable scrolling when the modal closes
            document.body.classList.remove('no-scroll');
        }
    };

    useEffect(() => {
        function handleKeyPress(event) {
            if (event.key === 'Escape') {
                // Execute toggleTab(0) when the Escape key is pressed
                document.body.classList.remove('no-scroll');
                toggleTab(0);
            }
        }

        // Add the event listener when the component mounts
        window.addEventListener('keydown', handleKeyPress);

        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [toggleState]);
    
    const variants = {
        open: {
          scale: 1,
          transition: {
            duration: 2,
          },
        },
        closed: {
          scale: 0,
          transition: {
            duration: 2,
          },
        },
      };
      
  return (
    <section className="services section" id="achievements">
        <h2 className="section__title bouncing-text" /**/>
        <div className="A">A</div>
            <div className="c">c</div>
            <div className="h">h</div>
            <div className="i">i</div>
            <div className="e1">e</div>
            <div className="v">v</div>
            <div className="e2">e</div>
            <div className="m">m</div>
            <div className="e3">e</div>
            <div className="n">n</div>
            <div className="t">t</div>
            {/* Achievement */}
        </h2>
        <span className="section__subtitle">- My role in daily life -</span>

        <div className="services__container container grid">
            <div className="services__content">
                <div>
                    <i className="uil uil-award services__icon"></i>
                    <h3 className="services__title">Active <br /> Competitor</h3>
                </div>

                <span className="services__button" onClick={() => toggleTab(1)}>
                    View More<i className="uil uil-arrow-right services__button-icon"></i>
                </span>

                <div className={toggleState === 1 ? "services__modal active-modal" : "services__modal"}>
                    <div className="services__modal-content">
                        <i onClick={() => toggleTab(0)} className="uil uil-times services__modal-close"></i>
                    
                        <h3 className="services__modal-title">
                            Active Competitor
                        </h3>
                        <p className="services__modal-description achievement__justify">
                        From university rookie to competition crusader, I've thrown myself into every challenge that came my way! Countless hours of training fueled my fire, and the victories? The awards, the recognition – those are just trophies for the thrill of the compete. Let's just say, I thrive under pressure and love the feeling of pushing my limits.
                        </p>

                        <div className="scrollable-element">

                            <ul className="services__modal-services grid">
                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info achievement__justify">
                                    Awarded  
                                        {' '}<b className="hover-text" style={{textDecoration: "underline"}}>1st Runner-Up among 26 teams</b>{' '}
                                        <img
                                            src={safetytech}
                                            alt="Image"
                                            className="popUpImg-left"
                                            style={{top: "5%"}}
                                        />
                                        for developing a 3D gaming website with mini games to enhance PPE training using Azure Services in the<b> Safety Tech Hackathon 2024</b>.
                                    </p>
                                </li>

                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info achievement__justify">
                                        Secured a  
                                        {' '}<b className="hover-text" style={{textDecoration: "underline"}}>top 5</b>{' '}
                                        <img
                                            src={branlitwin}
                                            alt="Image"
                                            className="popUpImg-left"
                                            style={{top: "5%"}}
                                        />
                                        position among 95 participants by developing a Python-based website in the<b> MY Streamlit Hack</b>.
                                    </p>
                                </li>

                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info achievement__justify">
                                        Achieved 
                                        {' '}<b className="hover-text" style={{textDecoration: "underline"}}>1st place</b>{' '}
                                        <img
                                            src={pcms}
                                            alt="Image"
                                            className="popUpImg-right"
                                            style={{top: "35%"}}
                                        />
                                        in the preliminary rounds of the <b>Innopolis' informatics competition</b>.
                                    </p>
                                </li>

                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info achievement__justify">
                                        Secured the 
                                        {' '}<b className="hover-text" style={{textDecoration: "underline"}}>12th position</b>{' '}
                                        <img
                                            src={deepracer}
                                            alt="Image"
                                            className="popUpImg-left"
                                            style={{top: "47%"}}
                                        />
                                        among 370 participating teams in the <b>APU AWS DeepRacer Competition 2023</b>.</p>
                                </li>

                                <li className="services__modal-service">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info achievement__justify">
                                        Awarded
                                        {' '}<b className="hover-text">Consolation Prize</b>{' '}
                                        in <b>BAT x APU Ideathon Competition 2023</b>
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="services__content">
                <div>
                    <i className="services__icon">
                        <TbCloudComputing />
                    </i>
                    <h3 className="services__title">
                        Cloud <br /> Enthusiast
                    </h3>
                </div>

                <span className="services__button" onClick={() => toggleTab(2)}>
                    View More <i className="uil uil-arrow-right services__button-icon"></i>
                </span>

                <div className={toggleState === 2 ? "services__modal active-modal" : "services__modal"}>
                    <div className="services__modal-content">
                        <i onClick={() => toggleTab(0)} className="uil uil-times services__modal-close"></i>
                    
                        <h3 className="services__modal-title">
                            Cloud Enthusiast  
                        </h3>
                        <p className="services__modal-description achievement__justify">
                        Full-stack development was my jam, but local machines felt like chains. Then, cloud computing swooped in, a superhero of flexibility and scale! Seeing it empower businesses ignited a spark. Free resources are everywhere, it's a cloud party, and I'm ready to join! Multi-cloud mastery, here I come! ☁️
                        </p>
                        <div className="scrollable-element">
                            <ul className="services__modal-services grid">
                                <li className="services__modal-service">
                                    <i className="services__modal-icon">
                                        <SiMicrosoftazure />
                                    </i>
                                    <p className="services__modal-info achievement__justify">
                                        Nailed the  
                                        {' '}<a href="https://learn.microsoft.com/en-us/users/bbkx/credentials/certification/azure-ai-engineer?tab=credentials-tab" target="_blank" rel="noreferrer"><b style={{ color: "#333333", textDecoration: "underline" }}>Microsoft Certified: Azure AI Engineer Associate (AI-102)</b></a>{' '}
                                        exam! <b>845/1000</b> – talk about acing it!
                                    </p>
                                </li>


                                <li className="services__modal-service">
                                    <i className="services__modal-icon">
                                        <SiMicrosoftazure />
                                    </i>
                                    <p className="services__modal-info achievement__justify">
                                        Nailed the  
                                        {' '}<a href="https://learn.microsoft.com/en-us/users/bbkx/credentials/certification/azure-ai-fundamentals?tab=credentials-tab" target="_blank" rel="noreferrer"><b style={{ color: "#333333", textDecoration: "underline" }}>Microsoft Certified: Azure AI Fundamentals (AI-900)</b></a>{' '}
                                        exam! <b>914/1000</b>
                                    </p>
                                </li>

                                <li className="services__modal-service">
                                    <i className="services__modal-icon">
                                        <FaAws />
                                    </i>
                                    <p className="services__modal-info achievement__justify">
                                        Nailed the
                                        {' '}<a href="https://www.credly.com/earner/earned/badge/57819b1f-ccde-4ae2-bd91-7d3975edbc98" target="_blank" rel="noreferrer"><b style={{ color: "#333333", textDecoration: "underline" }}>AWS Certified Cloud Practitioner (CLF-C02)</b></a>{' '}
                                        exam with a sizzling score of <b>838/1000!</b>
                                    </p>
                                </li>

                                <li className="services__modal-service">
                                    <i className="services__modal-icon">
                                        <FaGoogle />
                                    </i>
                                    <p className="services__modal-info achievement__justify">
                                        Snagged the
                                        {' '}<a href="https://www.credly.com/badges/90450c95-f01e-42ef-b943-558d529d9b5a/public_url" target="_blank" rel="noreferrer"><b style={{ color: "#333333", textDecoration: "underline" }}>Google IT Support Professional Certificate</b></a>{' '}
                                        (not exactly cloud-based, but hey, knowledge is power!)
                                    </p>
                                </li>

                                <li className="services__modal-service">
                                    <i className="services__modal-icon">
                                        <SiMicrosoftazure />
                                    </i>
                                    <p className="services__modal-info achievement__justify">
                                        Crushed the
                                        {' '}<a href="https://www.credly.com/badges/1235d2eb-fe9e-4603-bd5a-e1e19ba5c293/public_url" target="_blank" rel="noreferrer"><b style={{ color: "#333333", textDecoration: "underline" }}>Microsoft Azure Fundamentals (AZ-900)</b></a>{' '}
                                        exam with a score of <b>931/1000</b>. Onwards to cloud domination!
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="services__content">
                <div>
                    <i className="uil uil-edit services__icon"></i>
                    <h3 className="services__title">
                        Undergraduate <br/> Student
                    </h3>
                </div>
                
                <span className="services__button" onClick={() => toggleTab(3)}>View More <i className="uil uil-arrow-right services__button-icon"></i>
                </span>

                <div className={toggleState === 3 ? "services__modal active-modal" : "services__modal"}>
                    <div className="services__modal-content">
                        <i onClick={() => toggleTab(0)} className="uil uil-times services__modal-close"></i>
                    
                        <h3 className="services__modal-title">
                            Bachelor's Degree in Computer Science
                        </h3>
                        <p className="services__modal-description achievement__justify">
                        Yo! So, picture this: Asia Pacific University of Technology & Innovation, but instead of just another student zombie, you've got yours truly – caffeinated, curious, and maybe a little sleep-deprived from all the awesome stuff going on.
                        </p>
                    
                        <ul className="services__modal-services grid">
                            <li className="services__modal-service">
                                <i className="uil uil-check-circle services__modal-icon"></i>
                                <p className="services__modal-info achievement__justify">
                                    Achieved a <b className="hover-text" style={{textDecoration: "underline"}}>CGPA of 3.88 out of 4.0</b> 
                                    <img
                                        src={cgpa}
                                        alt="Image"
                                        className="popUpImg-right"
                                        style={{top: "0%", width: "90%", height: '100%'}}
                                    />
                                </p>
                            </li>

                            <li className="services__modal-service">
                                <i className="uil uil-check-circle services__modal-icon"></i>
                                <p className="services__modal-info achievement__justify">
                                    Named to the <b>Vice Chancellor's List</b> for academic years
                                    {` `}
                                    <b className="hover-text" style={{textDecoration: "underline"}}>2022</b> 
                                    <img
                                        src={vclist2022}
                                        alt="Image"
                                        className="popUpImg-left"
                                        style={{top: "0%", width: "90%", height: '100%'}}
                                    />
                                    {` `}and{` `}
                                    <b className="hover-text" style={{textDecoration: "underline"}}>2023</b> 
                                    <img
                                        src={vclist2023}
                                        alt="Image"
                                        className="popUpImg-right"
                                        style={{top: "0%", width: "90%", height: '100%'}}
                                    />
                                </p>
                            </li>

                            <li className="services__modal-service">
                                <i className="uil uil-check-circle services__modal-icon"></i>
                                <p className="services__modal-info achievement__justify">Chair and Webmaster of  
                                    {" "}<b className="hover-text" style={{textDecoration: "underline"}}> APU IEEE Student Branch</b> 2023/2024.
                                    <img
                                        src={ieee}
                                        alt="Image"
                                        className="popUpImg-right"
                                        style={{top: "10%", width: "100%", height: '70%'}}
                                    />
                                </p>
                            </li>

                            <li className="services__modal-service">
                                <i className="uil uil-check-circle services__modal-icon"></i>
                                <p className="services__modal-info achievement__justify">Treasury Department Lead - APU <b>AWS Cloud Club</b>.</p>
                            </li>

                            <li className="services__modal-service">
                                <i className="uil uil-check-circle services__modal-icon"></i>
                                <p className="services__modal-info achievement__justify">
                                    Earned 
                                    {' '}<b className="hover-text" style={{textDecoration: "underline"}}>7 ECTS</b>{' '}
                                    <img
                                        src={helsinki}
                                        alt="Image"
                                        className="popUpImg-left"
                                        style={{top: "0%", width: "90%", height: '100%'}}
                                    />
                                    credits from the <b>University of Helsinki</b> by achieving a score of <b>21.5 out of 26</b> and passing the <b>Full Stack Open</b> exam</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Services