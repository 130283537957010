import React, { useState } from 'react';
import "./home.css";
import Social from './Social';
import Data from './Data';
import ScrollDown from './ScrollDown';

const Home = () => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleClick = () => {
      setIsFlipped(!isFlipped);
  };

  return (
    <section className="home section" id="welcome">
        <div className="home__container container grid">
          <div className="home__content grid">
            <Social />
            <div className="home__img-container">
              <div 
                className={`home__img ${isFlipped ? 'flipped' : ''}`} 
                onClick={handleClick}
              >
              </div>
              <div className='home__barcode'></div>
            </div>
            <Data />
          </div>
          <ScrollDown />
        </div>
   </section>
  )
}

export default Home;