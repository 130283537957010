import React from 'react';
import "./skills.css";
import "./skills.scss";
import { FaRegPaperPlane } from 'react-icons/fa';
import { SiEslint, SiLabview } from 'react-icons/si';
import { FaDocker } from "react-icons/fa";
import { IoLogoFirebase } from "react-icons/io5";
import { DiFirebase } from 'react-icons/di';
import { AiFillOpenAI } from "react-icons/ai";

const Backend = () => {
  return (
    <div className="skills__content box-bottom-right">
        <h3 className="skills__title">Tools</h3>

        <div className="skills__box">
            <div className="skills__group">

                <div className="skills__data">
                <i className='bx bxl-git' ></i>
                <div>
                    <h3 className="skills__name">Git</h3>
                    <span className="skills__level">Intermediate</span>
                </div>
                </div>

                <div className="skills__data">
                <i className='bx bxl-figma' ></i>
                <div>
                    <h3 className="skills__name">Figma</h3>
                    <span className="skills__level">Intermediate</span>
                </div>
                </div>

                <div className="skills__data">
                    <i className=''>
                        <FaDocker />
                    </i>

                    <div>
                        <h3 className="skills__name">Docker</h3>
                        <span className="skills__level">Basic</span>
                    </div>
                </div>
            </div>

            <div className="skills__group">
                <div className="skills__data">
                    <i className=''>
                        <IoLogoFirebase />
                    </i>
                    <div>
                        <h3 className="skills__name">Firebase</h3>
                        <span className="skills__level">Basic</span>
                    </div>
                </div>

                <div className="skills__data">
                    <SiEslint />
                    <div>
                        <h3 className="skills__name">ESLint</h3>
                        <span className="skills__level">Basic</span>
                    </div>
                </div>

                <div className="skills__data">
                    <AiFillOpenAI />
                    <div>
                        <h3 className="skills__name">OpenAI</h3>
                        <span className="skills__level">Basic</span>
                    </div>
                </div>

            </div>
        </div>
    </div>
  )
}

export default Backend