import React from 'react';
import './App.css';
import ReactGA from 'react-ga4'
import Header from './components/header/Header';
import Home from './components/home/Home';
import About from './components/about/About';
import Skills from './components/skills/Skills';
import Services from './components/services/Services';
import Qualification from './components/qualification/Qualification';
import Work from './components/work/Work';
import Testimonials from './components/testimonials/Testimonials';
import Contact from './components/contact/Contact';
import Footer from './components/footer/Footer';
import ScrollUp from './components/scrollup/ScrollUp';

/* Define components */
/* component starts as a function: */
/* You can think of components as blueprints. 
Whatever a function component returns is rendered as a React element. 
React elements let you describe what you want to see on the screen. */
ReactGA.initialize('G-2K1N18R3TH')
ReactGA.send('pageview')

const App = () => {
  return (
    <>
    <Header />

    <main className='main'>
      <Home />
      <About />
      <Skills />
      <Services />
      <Qualification />
      <Work />
      <Testimonials />
      <Contact />
    </main>
    <Footer />
    <ScrollUp />
    </>
  )
}

export default App;
