import firstPage from "../../assets/Brandon-CV-1.png";
import secondPage from "../../assets/Brandon-CV-2.png";

export const Data = [
    {
        id: 1,
        image: firstPage,
        alt: "First Page",
    },
    {
        id: 2,
        image: secondPage,
        title: "Second Page",
    },
];
