import React, { useState, useEffect, useRef } from 'react';
import { TypeAnimation } from 'react-type-animation';
import "./home.css";
import "./title.scss";
import { SiPowershell } from "react-icons/si";
import { FaSignature } from "react-icons/fa";

// The syntax const Data = () => defines a constant variable named Data that is initialized with an anonymous function. 
// This anonymous function is an arrow function, which is a concise way of defining a function in JavaScript.
// The arrow function syntax consists of the => token, followed by a block of code that defines the function's behavior. 
// The () after the => token indicates that the function does not take any arguments.
const Data = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [key, setKey] = useState(0);
  const typeAnimationRef = useRef(null);

  const birthYear = 2002;
  const currentYear = new Date().getFullYear();
  const age = currentYear - birthYear;

  useEffect(() => {
    const observer = new IntersectionObserver(
        ([entry]) => {
            // Set isVisible to true when the element is intersecting
            // and to false when it's not
            setIsVisible(entry.isIntersecting);
            if (!entry.isIntersecting) {
              setKey(prevKey => prevKey + 1); // increment key to force re-render
            }
        },
        {
            root: null,
            rootMargin: '0px',
            threshold: 0.1
        }
    );

    if (typeAnimationRef.current) {
        observer.observe(typeAnimationRef.current);
    }

    return () => {
        if (typeAnimationRef.current) {
            observer.unobserve(typeAnimationRef.current);
        }
    };
}, []);

  return (
    <div className="home__data" ref={typeAnimationRef}>
        <h1 className="home__title cool" key={key}>
            {/* The data-* attribute is used to store custom data private to the page or application */}
            <span data-text="Brandon">Brandon</span>
            <span data-text=" ">&nbsp;</span>
            <span data-text="Ban">Ban</span>
            <FaSignature className='signature'/>

        </h1>
        <h3 className="home__subtitle">
          {' '}   
          {/* This is used to put an explicit space in a text block, 
          since leading and trailing spaces are ignored at compile/
          transformation time when there is another tag */}
          <TypeAnimation
            sequence={[
                'Full Stack Software Engineer',
                500,
                'Cloud Enthusiast',
                500,
                'I\'m INTJ-A',
                500,
                'Maths Lover',
                500,
                'Undergraduate Student',
                500,
                'Malaysian',
                500,
            ]}
            wrapper="div"
            cursor={true}
            repeat={Infinity}
          />
        </h3>
        
        <p className="home__description home__justify home__italic-text">
          Apa Khabar, Devs!  I'm a <span className="keyword">{age}-year-old</span>, <span className="keyword">full-stack software engineer</span> with a thirst for knowledge that never seems to quench.  Currently a <span className="keyword">final year computer science student</span> in <span className="keyword">Malaysia</span>, I see every challenge as a springboard to learn and grow.  Fueled by the belief that "the more you know, the more you discover," I'm constantly pushing myself to break new ground and expand my skillset.
        </p>

        <p className="home__description home__justify">
          {isVisible && (<TypeAnimation
            sequence={[
                'Let\'s connect! Carpe diem 🫵',
                5000,
            ]}
            wrapper="span"
            cursor={true}
            repeat={0}
            omitDeletionAnimation={true}
          />)}
        </p>

        <a href="#contact" className="button button--flex content">
          <SiPowershell style={{ marginRight: '10px' }}/> Jom Connect 
        </a>
    </div>
  )
}

export default Data