import React from 'react'
import "./home.css";
import { SiGooglecloud, SiCredly } from "react-icons/si";

const Social = () => {
  
  return (
    <div className="home__social">
        <a href="https://www.linkedin.com/in/bbkx/" className="home__social-icon" target="_blank" rel="noopener noreferrer">
        <i className="uil uil-linkedin"></i>
        </a>
        <a href="https://github.com/bbkx226" className="home__social-icon" target="_blank" rel="noopener noreferrer">
        <i className="uil uil-github-alt"></i>
        </a>
        <a href="https://www.credly.com/users/brandon-ban-kai-xian/badges" className="home__social-icon" target="_blank" rel="noopener noreferrer">
          <SiCredly />
        </a>
        <a href="https://www.cloudskillsboost.google/public_profiles/8985c65b-6ea4-42af-ade2-3fe66b0992b4" className="home__social-icon" target="_blank" rel="noopener noreferrer">
          <SiGooglecloud />
        </a>

    </div>
  )
}

export default Social