import React from 'react';
import "./skills.css";
import "./skills.scss";
import { FaNodeJs } from 'react-icons/fa'; 
import { SiExpress } from 'react-icons/si';
import { FaPython } from "react-icons/fa";
import { BiLogoPostgresql } from "react-icons/bi";
import { DiMsqlServer } from "react-icons/di";


const Backend = () => {
  return (
    <div className="skills__content box-top-right">
        <h3 className="skills__title">Backend</h3>

        <div className="skills__box">
            <div className="skills__group">

                <div className="skills__data">
                <FaNodeJs />
                <div>
                    <h3 className="skills__name">Node.js</h3>
                    <span className="skills__level">Basic</span>
                </div>
                </div>

                <div className="skills__data">
                <SiExpress />
                <div>
                    <h3 className="skills__name">Express.js</h3>
                    <span className="skills__level">Basic</span>
                </div>
                </div>

                <div className="skills__data">
                    <i className=''>
                        <FaPython/>
                    </i>
                    <div>
                        <h3 className="skills__name">Python</h3>
                        <span className="skills__level">Intermediate</span>
                    </div>
                </div>
            </div>

            <div className="skills__group">
                <div className="skills__data">
                    <i className=''>
                        <DiMsqlServer />
                    </i>

                    <div>
                        <h3 className="skills__name">MS SQL</h3>
                        <span className="skills__level">Basic</span>
                    </div>
                </div>

                <div className="skills__data">
                    <BiLogoPostgresql />
                    <div>
                        <h3 className="skills__name">PostgreSQL</h3>
                        <span className="skills__level">Basic</span>
                    </div>
                </div>

                <div className="skills__data">
                    <i className='bx bxl-mongodb' ></i>
                <div>
                    <h3 className="skills__name">MongoDB</h3>
                    <span className="skills__level">Basic</span>
                </div>

                
                </div>
            </div>
        </div>
    </div>
  )
}

export default Backend