import React from 'react';
import "./skills.css";
import "./skills.scss";
import { SiStorybook, SiTailwindcss, SiTypescript } from 'react-icons/si';

const Frontend = () => {
  return (
    <div className="skills__content box-top-left">
        <h3 className="skills__title">Frontend</h3>

        <div className="skills__box">
            <div className="skills__group">
                <div className="skills__data">
                <i className='bx bxl-html5'></i>

                <div>
                    <h3 className="skills__name">HTML</h3>
                    <span className="skills__level">Intermediate</span>
                </div>
                </div>

                <div className="skills__data">
                <i className='bx bxl-css3' ></i>

                <div>
                    <h3 className="skills__name">CSS</h3>
                    <span className="skills__level">Intermediate</span>
                </div>
                </div>

                <div className="skills__data">
                <i className='bx bxl-javascript' ></i>

                <div>
                    <h3 className="skills__name">Javascript</h3>
                    <span className="skills__level">Intermediate</span>
                </div>
                </div>
            </div>

            <div className="skills__group">
                <div className="skills__data">
                <i className='bx bxl-react'></i>

                <div>
                    <h3 className="skills__name">React</h3>
                    <span className="skills__level">Intermediate</span>
                </div>
                </div>

                <div className="skills__data">
                <SiTypescript />

                <div>
                    <h3 className="skills__name">TypeScript</h3>
                    <span className="skills__level">Basic</span>
                </div>
                </div>

                <div className="skills__data">
                <SiStorybook />

                <div>
                    <h3 className="skills__name">Storybook</h3>
                    <span className="skills__level">Intermediate</span>
                </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Frontend