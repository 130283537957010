import React from 'react';
import "./skills.css";
import "./skills.scss";
import { FaCuttlefish, FaRProject } from 'react-icons/fa';
import { SiFlask, SiMicrosoftsqlserver } from 'react-icons/si';
import { RiTailwindCssFill } from "react-icons/ri";
import { SiCypress, SiJest } from "react-icons/si";
import { RiNextjsFill } from "react-icons/ri";
import { FaLocust } from "react-icons/fa6";


const Frontend = () => {
  return (
    <div className="skills__content box-bottom-left">
        <h3 className="skills__title">Frameworks</h3>

        <div className="skills__box">
            <div className="skills__group">
                <div className="skills__data">
                    <i className=''>
                        <SiFlask />
                    </i>

                    <div>
                        <h3 className="skills__name">Flask</h3>
                        <span className="skills__level">Intermediate</span>
                    </div>
                </div>
                

                <div className="skills__data">
                    <i className=''>
                        <SiCypress />
                    </i>

                    <div>
                        <h3 className="skills__name">Cypress</h3>
                        <span className="skills__level">Intermediate</span>
                    </div>
                </div>

                <div className="skills__data">
                    <i className=''>
                        <FaLocust />
                    </i>

                    <div>
                        <h3 className="skills__name">Locust</h3>
                        <span className="skills__level">Basic</span>
                    </div>
                </div>
            </div>

            <div className="skills__group">


                <div className="skills__data">
                    <i className=''>
                        <SiJest />
                    </i>    
                    <div>
                        <h3 className="skills__name">Jest</h3>
                        <span className="skills__level">Intermediate</span>
                    </div>
                </div>

                <div className="skills__data">
                    <i className=''>
                        <RiNextjsFill />
                    </i>

                    <div>
                        <h3 className="skills__name">NextJS</h3>
                        <span className="skills__level">Basic</span>
                    </div>
                </div>

                <div className="skills__data">
                    <i className=''>
                        <RiTailwindCssFill />
                    </i>
                    <div>
                        <h3 className="skills__name">Tailwind CSS</h3>
                        <span className="skills__level">Basic</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Frontend